import { useEffect } from "react";
// import useDrivePicker from "react-google-drive-picker";

function UploadDrive() {
  // const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  // const handleOpenPicker = () => {
  //   openPicker({
  //     clientId: "xxxxxxxxxxxxxxxxx",
  //     developerKey: "xxxxxxxxxxxx",
  //     viewId: "DOCS",
  //     token: "AIzaSyBCwvse94gb56M14y264s5TuDy8gwwvmC8", // pass oauth token in case you already have one
  //     showUploadView: true,
  //     showUploadFolders: true,
  //     supportDrives: true,
  //     multiselect: true,
  //     // customViews: customViewsArray, // custom view
  //     callbackFunction: (data) => {
  //       if (data.action === "cancel") {
  //         console.log("User clicked cancel/close button");
  //       }
  //       console.log(data);
  //     },
  //   });
  // };

  return (
    <div>
      <button>Open Picker</button>
    </div>
  );
}

export default UploadDrive;
